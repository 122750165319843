const subMenuTriggers = document.querySelectorAll('.js-show-submenu');

const hideAllSubmenu = (exclude) => {
  for (let i = 0; i < subMenuTriggers.length; i++) {
    if (subMenuTriggers[i] === exclude) continue;

    subMenuTriggers[i].classList.remove('dropped');
    subMenuTriggers[i].nextElementSibling.classList.remove('show');
  }
};

for (let i = 0; i < subMenuTriggers.length; i++) {
  const trigger = subMenuTriggers[i];

  trigger.addEventListener('click', (e) => {
    const currentTarget = e.currentTarget;

    hideAllSubmenu(currentTarget);

    currentTarget.classList.toggle('dropped');
    currentTarget.nextElementSibling.classList.toggle('show');
  });
}
