import './main.css';

import '@splidejs/splide/css/core';

import './assets/js/header.js';
import './assets/js/header-menu.js';

import Splide from '@splidejs/splide';
import ClipboardJS from 'clipboard';

const clipboard = new ClipboardJS('.copy-it');

const createClipboardMessage = (type, msg) => {
  const div = document.createElement('div');
  div.className = `clipboard-message ${type}`;
  div.textContent = msg;

  document.body.append(div);
}

clipboard.on('success', function () {
  const elm = document.querySelector('.clipboard-message');
  if (elm) elm.remove();

  createClipboardMessage('success', 'Скопировали');
});

clipboard.on('error', function () {
  const elm = document.querySelector('.clipboard-message');
  if (elm) elm.remove();

  createClipboardMessage('error', 'Ошибка копирования');
});

const formPopupElement = document.querySelector('.form-popup');
const closePopupFormBtn = document.querySelector('.js-close-popup-form-btn');

if (formPopupElement) {
  document.body.addEventListener('click', (e) => {
    const openPopupFormBtn = e.target.closest('.js-open-popup-form-btn');

    if (openPopupFormBtn) {
      e.preventDefault();

      formPopupElement.classList.add('show');
      document.body.style.overflow = 'hidden';

      return false;
    }
  });

  formPopupElement.addEventListener('click', (e) => {
    if (e.target === formPopupElement) {
      formPopupElement.classList.remove('show');
      document.body.style.overflow = null;
    }
  });

  closePopupFormBtn.addEventListener('click', () => {
    formPopupElement.classList.remove('show');
    document.body.style.overflow = null;
  });
}

document.addEventListener('click', (e) => {
  const closeButton = e.target.closest('.close-bubbles');

  if (closeButton) {
    const closeItElement = closeButton.closest('.close-it');

    if (closeItElement) {
      closeItElement.remove();
    }
  }
});

if (document.querySelectorAll('.header-slider').length) {
  new Splide('.header-slider', {
    type: 'loop',
    arrows: {
      prev: document.querySelector('.header-slider__arrow--prev'),
      next: document.querySelector('.header-slider__arrow--next'),
    },
    autoHeight: true,
  }).mount();
}

const ourWorkSliders = document.querySelectorAll('.splide--our-work:not(.splide--work-examples)');
if (ourWorkSliders.length) {
  for (let i = 0; i < ourWorkSliders.length; i++) {
    const slider = ourWorkSliders[i];

    new Splide(slider, {
      type: 'loop',
      arrows: {
        prev: slider.querySelector('.splide__arrow--prev'),
        next: slider.querySelector('.splide__arrow--prev'),
      },
    }).mount();
  }
}
