const hambElement = document.getElementById('hamb');
const menu = document.getElementById('menu');

hambElement.addEventListener('click', (e) => {
  const curTarget = e.currentTarget;

  if (curTarget.classList.contains('show')) {
    curTarget.classList.remove('show');
  } else {
    curTarget.classList.add('show');
  }

  menu.classList.toggle('show');
});
